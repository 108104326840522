import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20cccab6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dropdown" }
const _hoisted_2 = { class: "dropdown-trigger" }
const _hoisted_3 = ["aria-controls"]
const _hoisted_4 = { class: "icon" }
const _hoisted_5 = ["id"]
const _hoisted_6 = { class: "dropdown-content" }
const _hoisted_7 = ["onClick"]
const _hoisted_8 = { class: "mr-1 icon-container" }
const _hoisted_9 = { class: "is-small ml-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_fa_icon = _resolveComponent("fa-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("span", {
        class: "button is-default button-contextual-menu is-pulled-right is-rounded refresh ml-2",
        "aria-haspopup": "true",
        "aria-controls": `dropdown-${_ctx.object.backend}-menu`
      }, [
        _createElementVNode("span", _hoisted_4, [
          _createVNode(_component_fa_icon, {
            class: "qrcode-icon",
            icon: "ellipsis-v"
          })
        ])
      ], 8, _hoisted_3)
    ]),
    _createElementVNode("div", {
      class: "dropdown-menu",
      id: `dropdown-${_ctx.object.backend}-menu`,
      role: "menu"
    }, [
      _createElementVNode("div", _hoisted_6, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.$dropdownMenu.listItems(_ctx.object), (item) => {
          return (_openBlock(), _createElementBlock("a", {
            key: item.label,
            href: "#",
            class: "dropdown-item is-flex",
            onClick: ($event: any) => (item.action(_ctx.refreshTransaction))
          }, [
            _createElementVNode("div", _hoisted_8, [
              _createVNode(_component_fa_icon, {
                icon: item.icon
              }, null, 8, ["icon"])
            ]),
            _createElementVNode("div", _hoisted_9, _toDisplayString(item.label), 1)
          ], 8, _hoisted_7))
        }), 128))
      ])
    ], 8, _hoisted_5)
  ]))
}